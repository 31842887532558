import { Box, Button, MenuItem, Select, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { getName } from "../helpers/helpers";
import SuccesMessage from "../SuccesMessage";
import ErrorMessage from "../ErrorMessage";
import CancelDialog from "./CancelDialog";
import { useDispatch } from "react-redux";
import { ticketAddComment, ticketSave } from "../../actions/ticket";
import SimpleForm from "@ksbteam/core/components/Form/SimpleForm";

const useStyles = makeStyles((theme) => ({
  appointWrapper: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  appointContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(4),
  },
  appointSelect: {
    minWidth: "30%",
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    justifyContent: "flex-start",
  },
  agreeButton: {
    background: "green",
    color: "white",
    "&:hover": {
      background: "green",
    },
  },
}));

function Assign({
  restClient,
  tiketID,
  closeTicket,
  projectId,
  isAdmin,
  IsDirector,
  IsDircontractor,
  IsContractor,
  IsSubcontractor,
  status,
  editDisabled,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [assignConfig, setAssignConfig] = React.useState({});
  const [assignRef, setAssignRef] = React.useState(null);
  const [isButtonDisadled, setIsButtonDisadled] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const shouldRenderCloseButton =
    (IsDirector && status.value !== "1569999") ||
    (IsDircontractor && (status.value === "1569912" || status.value === "1569911" || status.value === "1569999")) ||
    ((IsSubcontractor || IsContractor) && status.value === "1569912") ||
    isAdmin;

  const shouldRenderBackButton =
    (!IsDircontractor && (status.value === "1570001" || status.value === "1569999")) || (IsDircontractor && status.value === "1569912");

  const handleClick = async () => {
    if (restClient && tiketID) {
      try {
        assignRef.formik.handleSubmit();
      } catch (error) {
        console.error("Error fetching assign type", error);
        setErrorMessage("Ошибка назначения ответственного, назначьте еще раз или попробуйте позже");
      }
    }
  };

  const handleChangeStatus = async (reason, status) => {
    let sendStatus = {};
    sendStatus.status_code = status;
    try {
      let statusResponse = await restClient.post(`/service_desc/ticket/define-status/?ticket_id=${tiketID}&project_id=${projectId}`, sendStatus);
      if (reason) {
        dispatch(ticketAddComment(reason));
      }
      closeTicket();
    } catch (e) {
      console.log("Ошибка отказа", e);
    }
  };

  const handleButtonDisable = () => {
    const isValidForm = assignRef?.isValid;
    
    const hasDirContractor = !!assignRef?.values["sd_resp-dircontractor"];
    const hasContractor = !!assignRef?.values["sd_resp-contractor"];
    const hasSubcontractor = !!assignRef?.values["sd_resp-subcontractor"];
    const hasContractorPerformer = !!assignRef?.values["sd_resp-performer_contractor"];
    const hasSubcontractorPerformer = !!assignRef?.values["sd_resp-performer_subcontractor"];
    
    // const isContractorChanged = assignRef?.values["sd_resp-contractor"] !== assignConfig?.values["sd_resp-contractor"];
    // const isContractorPerformerChanged = assignRef?.values["sd_resp-performer_contractor"] !== assignConfig?.values["sd_resp-performer_contractor"];
    // const isSubcontractorPerformerChanged =
    // assignRef?.values["sd_resp-performer_subcontractor"] !== assignConfig?.values["sd_resp-performer_subcontractor"];
    // const isSubcontractorChanged = assignRef?.values["sd_resp-subcontractor"] !== assignConfig?.values["sd_resp-subcontractor"];
    
    const isValidAdmin = isAdmin && hasDirContractor;

    const isValidDirContractor = IsDircontractor && hasContractor;
    
    const IsValidContractor =
    (IsContractor && hasSubcontractor) ||
    (hasSubcontractorPerformer) ||
    (hasContractorPerformer);
    
    const IsValidSubcontractor = IsSubcontractor && hasSubcontractorPerformer;
    
    const isValid = isValidForm && (isValidAdmin || isValidDirContractor || IsValidContractor || IsValidSubcontractor);

    if (isValid !== undefined) {
      setIsButtonDisadled(!isValid);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (restClient && tiketID) {
        try {
          const result = await restClient.get(`/service_desc/ticket/responsible-list/?ticket_id=${tiketID}`);
          setAssignConfig(result);
        } catch (error) {
          console.error("Error fetching responsible config", error);
        }
      }
    };

    fetchData();
  }, [restClient, tiketID]);

  return (
    !!Object.keys(assignConfig).length && (
      <Box className={classes.appointWrapper}>
        <Typography variant="h6">Назначить ответственного</Typography>
        <SimpleForm
          ref={(simpleForm) => {
            if (!assignRef && !!simpleForm) {
              setAssignRef(simpleForm);
            }
          }}
          {...assignConfig}
          inited={true}
          restClient={restClient}
          hideSubmitButton={true}
          initValues={assignConfig.values}
          onValidate={handleButtonDisable}
        />
        <Box className={classes.buttons}>
          <Button
            variant="contained"
            disabled={isButtonDisadled || editDisabled}
            onClick={handleClick}
            className={classes.agreeButton}>
            Назначить
          </Button>
          {shouldRenderCloseButton && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleChangeStatus("", "closed")}>
              Закрыть
            </Button>
          )}
          {shouldRenderBackButton && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsDialogOpen(true)}>
              Вернуть на доработку
            </Button>
          )}
        </Box>
        <SuccesMessage
          isOpen={!!successMessage}
          onClose={() => setSuccessMessage("")}
          text={successMessage}
        />
        <ErrorMessage
          isOpen={!!errorMessage}
          onClose={() => setErrorMessage("")}
          text={errorMessage}
        />
        <CancelDialog
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          reasonText="того, почему инцидент необходимо вернуть на доработку"
          handleSubmit={handleChangeStatus}
          status={"rework"}
        />
      </Box>
    )
  );
}

export default Assign;
